import getData from "@/__main__/get-data.mjs";
import valorantMatchData from "@/data-models/valorant-api-match-data.mjs";
import ValorantLatestPlayerStats from "@/data-models/valorant-latest-player-stats.mjs";
import ValorantPlayerMatchesModel from "@/data-models/valorant-player-matches.mjs";
import valorantRankWeaponData from "@/data-models/valorant-rank-weapon-data.mjs";
import * as API from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import isValidNameTag from "@/game-val/is-valid-nametag.mjs";
import { getActiveSeason, separateNameTag } from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devLog, devWarn } from "@/util/dev.mjs";

export default async function fetchData(queryParams, urlSearchParams, state) {
  const {
    profileId,
    actId: actIdParam,
    queue: queueParam,
    matchId: paramMatchId,
  } = state;
  if (!profileId || !isValidNameTag(profileId)) return;
  const { name, tag } = separateNameTag(profileId);
  const valorantPlayer = await fetchProfile(name, tag);
  if (!valorantPlayer || !valorantPlayer.valorantProfile?.region) {
    devWarn("Region is missing in profile data", valorantPlayer);
    return;
  }

  const headers = await getAuthorizationHeaders();
  const [content] = await fetchConstants();
  const latestActId = content.acts?.find((act) => act.endedAt === null)?.uuid;
  const actId = actIdParam || latestActId;
  const promises = [];
  const platformPath = getPlatformPath(false); // PC only for overlays

  const puuid = valorantPlayer.puuid;

  let latestMatchId;
  if (!paramMatchId) {
    const { id: actId } = getActiveSeason(content?.acts);
    const playerMatches = await getData(
      API.getPlayerMatches({
        puuid,
        actUuid: actId,
        offset: 0,
        first: 1,
      }),
      ValorantPlayerMatchesModel,
      ["val", "playerMatches", puuid, actId, platformPath, "overall"],
      {
        mergeFn: (curValues, newValues) => {
          const filteredNewValues = newValues.filter((newMatch) => {
            return !curValues.find(
              (c) => c.match.gameId === newMatch.match.gameId,
            );
          });
          const allValues = [...curValues, ...filteredNewValues];
          return allValues.sort(
            (a, b) => new Date(b.match.gameStart) - new Date(a.match.gameStart),
          );
        },
        headers,
      },
    );
    if (playerMatches.length !== 0) {
      // hack so that in case playerMatches is empty, it wont throw error in the next line by creating a proxy.
      latestMatchId = playerMatches?.[0]?.match?.gameId;
    }
  }

  const matchId = paramMatchId || latestMatchId;
  let match;
  if (matchId) {
    try {
      match = await getData(
        API.getValorantMatch({
          gameId: matchId,
          region: valorantPlayer.valorantProfile.region,
          actUuid: actId,
          console: false,
        }),
        valorantMatchData,
        ["val", "match", matchId],
        {
          shouldFetchIfPathExists: false,
          headers,
        },
      );
    } catch (e) {
      devLog(e);
    }
  }

  const queue = match?.queue || queueParam;
  if (queue) {
    const getLatestPlayerStats = getData(
      API.getLatestPlayerStats({
        puuid: valorantPlayer.puuid,
        region: valorantPlayer.valorantProfile.region,
        queue,
      }),
      ValorantLatestPlayerStats,
      ["val", "latestPlayerStats", valorantPlayer.puuid, queue],
      { headers },
    );
    promises.push(getLatestPlayerStats);
  }

  const divisionStats = getData(
    API.getRankWeaponStats(),
    valorantRankWeaponData,
    ["val", "rankweapons"],
  );
  promises.push(divisionStats);

  return Promise.all(promises);
}
